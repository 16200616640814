.home {
  height: 100vh;
}

.background {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-color: #000000;
  height: 100vh;
  overflow: hidden;
}

.main {
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.35);
  display: flex;
  text-align: center;
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  border: solid 1px;
  border-radius: 0.3rem;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.35);
}

.main-overlay {
  background-color: rgba(255, 255, 255, 0.644);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.title {
  font-size: 3rem;
  margin-top: 0px;
  margin-bottom: 1rem;
}

.sub-title {
  font-size: 1.5rem;
  margin: 0px;
}

.contact {
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
}
.logos-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30%;
}

.info-container {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cristina-logo {
  max-width: 100%;
}

.dulce-logo {
  margin-top: 1.25rem;
  max-width: 100%;
}

.contacts-container {
  display: flex;
  margin-bottom: 0px;
}

.address-list {
  list-style: none;
  padding-left: 0px;
  text-align: right;
  border-right: solid black 1px;
  padding-right: 1rem;
  width: 50%;
}

.contact-list {
  list-style: none;
  padding-left: 0px;
  text-align: left;
  width: 50%;
  margin-left: 1rem;
}

.emails-list {
  list-style: none;
  padding-left: 0px;
}

@media screen and (max-width: 750px) {
  .background {
    height: 100vh;
  }
  .contacts-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .info-container {
    width: 100%;
  }
  .address-list {
    text-align: center;
    border-right: none;
    padding-right: 0px;
    margin-bottom: 0px;
    width: 80%;
  }
  .contact-list {
    margin-left: 0px;
    text-align: center;
    width: 80%;
  }
  .title {
    font-size: 1.8rem;
    margin-top: 1.3rem;
    margin-bottom: 0.3rem;
  }
  .main {
    flex-direction: column;
    width: 70%;
    padding: 0.8rem;
  }
  .logos-container {
    display: -webkit-box;
    display: -moz-box;
    width: 100%;
  }

  .dulce-logo {
    max-width: 35%;
    height: auto;
    padding: 0.5rem;
    margin-top: 0px;
  }
  .cristina-logo {
    max-width: 45%;
    height: auto;
    padding: 0.5rem;
  }
}
